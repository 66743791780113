import {
  createContext, useCallback, useEffect, useState,
} from 'react';
import api from '../../lib/api';
import { Vessel } from '../../lib/types';

export const vesselContext = createContext<Vessel[]>([]);

function VesselProvider({ children }: { children:any }) {
  const [vessels, setVessels] = useState([]);

  const getVessels = useCallback(() => {
    api.get(['vessels']).then((response) => {
      setVessels(response.data);
    });
  }, []);

  useEffect(() => {
    getVessels();
  }, [getVessels]);

  const { Provider } = vesselContext;
  return (
    <Provider value={vessels}>
      {children}
    </Provider>
  );
}

export default VesselProvider;
