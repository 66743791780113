import { colors, spacing } from 'toc-styled-components';
import {
  Tab, Tabs, TabList, TabProps,
} from 'react-tabs';
import styled from 'styled-components';

function CustomTab({ children, className, ...props }: TabProps) {
  return (
    <Tab
      className={className}
      selectedClassName={`${className}--selected`}
      disabledClassName={`${className}--disabled`}
      {...props}
    >
      {children}
    </Tab>
  );
}
CustomTab.tabsRole = 'Tab';

export const StyledTabs = styled(Tabs)`
    margin-top: ${spacing(2)};
    color: ${colors.secondary};
`;
export const StyledTabList = styled(TabList)`
  border-bottom: 1px solid ${colors.secondary};
  margin: 0 0 10px;
  padding: 0;
`;

export const StyledTab = styled(CustomTab)`
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;

  &--selected {
    background: #fff;
    border-color: ${colors.secondary};
    color: ${colors.primary};
    border-radius: 5px 5px 0 0;
  }

  &--disabled{
    color: GrayText;
    cursor: default;
  }

  &:focus {
    outline: none;
    color: ${colors.primary}
  }

  &:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
`;
