import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Loader } from 'toc-styled-components';
import { TabPanel } from 'react-tabs';
import api from '../../lib/api';
import { filterObject, capitalize } from '../../lib/helpers';
import { Loading, Storage } from '../../lib/types';
import CRUDControls from '../CRUDControls/CRUDControls';
import DataParserTable from '../DataParserTable/DataParserTable';

import StorageControls from './components/StorageControls/StorageControls';
import { InlineH2, LoadingContainer } from '../../shared/layout';
import EditStorageFields from './components/StorageControls/components/EditStorageFields';
import { StyledTab, StyledTabList, StyledTabs } from '../Tabs/Tabs';
import StorageWeightDetails from './components/StorageWeightDetails/StorageWeightDetails';
import StorageHistory from './components/StorageHistory/StorageHistory';
import StorageOverview from './components/StorageOverview/StorageOverview';

function StorageDetails() {
  const { storageId, loadingId } = useParams();
  const navigate = useNavigate();
  const [storage, setStorage] = useState<Storage>();
  const [loading, setLoading] = useState<Loading>();
  const [pageLoading, setPageLoading] = useState(true);

  const storageKeys = ['tare_weight', 'container_type'];

  const viewOnlyMode = loading && (['embedded', 'offloaded'].includes(loading?.status));

  const getStorage = useCallback(() => {
    setPageLoading(true);

    if (!loadingId) {
      api.get(['storage', storageId!.toString()]).then((response) => {
        setStorage(response.data);
        if (response.data.current_loading_id) {
          api.get(['loadings', response.data.current_loading_id.toString()]).then((ldResponse) => {
            setLoading(ldResponse.data);
          });
        } else {
          setLoading(undefined);
        }
      }).finally(() => setPageLoading(false));
    } else {
      api.get(['loadings', loadingId!.toString()]).then((response) => {
        setLoading(response.data);
        setStorage(response.data.storage);
      }).finally(() => setPageLoading(false));
    }
  }, [storageId, loadingId]);

  useEffect(() => {
    getStorage();
  }, [getStorage]);

  if (pageLoading) return <LoadingContainer><Loader variant="secondary" /></LoadingContainer>;

  return (
    <Card>
      <InlineH2>{`${capitalize(storage!.storage_type)} ${storage!.identifier}`}</InlineH2>
      {storage && !viewOnlyMode && (
        <>
          <CRUDControls
            resourceId={storage.id}
            resource={storage}
            editFields={<EditStorageFields />}
            name="storage"
            onUpdate={getStorage}
            disableDelete={loading != null}
            onDeleteUpdate={() => navigate('/storage')}
          />
          <StorageControls
            onUpdate={getStorage}
            storage={storage}
            loading={loading}
          />
        </>
      )}
      { storage && (
      <div>
        <DataParserTable loading={pageLoading} data={filterObject(storage!, storageKeys)} />

        <StyledTabs>
          <StyledTabList>
            <StyledTab>Overview</StyledTab>
            <StyledTab disabled={!loading}>Contents</StyledTab>
            <StyledTab>History</StyledTab>
          </StyledTabList>
          <TabPanel>
            <StorageOverview storage={storage} loading={loading} setLoading={setLoading} getStorage={getStorage} />
          </TabPanel>
          <TabPanel>
            <StorageWeightDetails loading={loading} />
          </TabPanel>
          <TabPanel>
            <StorageHistory storage={storage} />
          </TabPanel>
        </StyledTabs>
      </div>
      )}
    </Card>
  );
}

export default StorageDetails;
