import styled from 'styled-components';
import { breakpoints, spacing } from 'toc-styled-components';

export const MainContent = styled.main`
  width: 100%;
  max-width: ${breakpoints.lg};
  margin: auto;
  padding: ${spacing(2)};
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClickTarget = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: none;
`;

export const StyledH2 = styled.h2`
  display: flex;
  justify-content: space-between;
  > div {
    font-size: initial;
    font-weight: normal;
  }
`;

export const InlineH2 = styled(StyledH2)`
  display: inline-block;
`;
