import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Loader } from 'toc-styled-components';
import api from '../../../../lib/api';
import { parseNumber } from '../../../../lib/helpers';
import {
  LoadingContents, WasteDistribution, Consolidate, Loading,
} from '../../../../lib/types';
import DataParserTable from '../../../DataParserTable/DataParserTable';
import { LoadingContainer } from '../../../../shared/layout';

type Props = {
  loading?: Loading
};

function StorageWeightDetails({ loading }: Props) {
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(true);
  const [contents, setContents] = useState<LoadingContents>();

  useEffect(() => {
    setPageLoading(true);
    if (loading) {
      api.get(['loadings', loading.id.toString(), 'contents']).then((wdResponse) => {
        setContents(wdResponse.data);
      }).finally(() => setPageLoading(false));
    } else {
      setPageLoading(false);
    }
  }, [loading]);

  const wasteDistributionTable = (data: WasteDistribution[]) => (
    <Table loading={pageLoading} overflowy="auto" fullWidth>
      <tbody>
        <tr>
          <th>{t('identifier')}</th>
          <th>{t('extraction')}</th>
          <th>{t('wasteType')}</th>
          <th>{t('kilograms')}</th>
          <th>{t('packaging')}</th>
        </tr>
        {data.map((s) => (
          <tr>
            <td>{s.identifier}</td>
            <td>{s.extraction_name}</td>
            <td>{s.waste_type}</td>
            <td>{parseNumber(s.netto_kg)}</td>
            <td>{parseNumber(s.packaging_kg)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const consolidateTable = (data: Consolidate[], out: boolean) => (
    <Table loading={pageLoading} overflowy="auto" fullWidth>
      <tbody>
        <tr>
          <th>{out ? t('consolidateToIdentifier') : t('consolidateFromIdentifier')}</th>
          <th>{t('wasteType')}</th>
          <th>{t('kilograms')}</th>
          <th>{t('packaging')}</th>
        </tr>
        {data.map((s) => (
          <tr>
            <td>{out ? s.to_identifier : s.from_identifier}</td>
            <td>{s.waste_type}</td>
            <td>{parseNumber(s.netto_kg)}</td>
            <td>{parseNumber(s.packaging_kg)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  if (pageLoading) return <LoadingContainer><Loader variant="secondary" /></LoadingContainer>;

  return (
    <>
      {!contents && <div>No Content in container</div>}
      {contents && contents.totals && (
        <DataParserTable
          title={t('loadingWeights')}
          loading={pageLoading}
          data={contents.totals}
        />
      )}
      {contents && contents!.waste_distributions?.length > 0 && (
        <div>
          <h2>{t('wasteDistributions')}</h2>
          {wasteDistributionTable(contents?.waste_distributions)}
        </div>
      )}

      {contents && contents!.consolidations_out?.length > 0 && (
        <div>
          <h2>{t('consolidationsOut')}</h2>
          {consolidateTable(contents.consolidations_out, true)}
        </div>
      )}
      {contents && contents!.consolidations_in?.length > 0 && (
        <div>
          <h2>{t('consolidationsIn')}</h2>
          {consolidateTable(contents.consolidations_in, false)}
        </div>
      )}
    </>
  );
}

export default StorageWeightDetails;
