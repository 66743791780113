import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Modal, useAlert } from 'toc-styled-components';
import { Props as ModalProps } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../../../lib/api';
import LoadingIdSelect
  from '../../../../../Form/LoadingIdSelect/LoadingIdSelect';

type Props = { id: number, vesselId? :number } & ModalProps;

function EmbedModal({
  id, onConfirm, vesselId, ...props
}: Props) {
  const { register, getValues, formState } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();

  const embed = () => {
    api.post(['loadings', id.toString(), 'embed'], getValues()).then(() => {
      onConfirm?.();
      alert({ message: t('completedSuccessfully') });
    });
  };

  return (
    <Modal
      footer={(
        <Button disabled={!formState.isValid} onClick={embed}>
          {t('add')}
        </Button>
      )}
      {...props}
    >
      <LoadingIdSelect
        vesselId={vesselId}
        label={t('embedInto')}
        q="storage.storage_type=container"
        {...register('parent_loading_id')}
      />
    </Modal>
  );
}

export default EmbedModal;
