import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, spacing } from 'toc-styled-components';

import styled from 'styled-components';
import { Storage, Loading } from '../../../../lib/types';
import Form from '../../../Form/Form';
import ConsolidateModal from './components/consolidateModal/ConsolidateModal';
import EmbedModal from './components/EmbedModal/EmbedModal';
import LoadStorageModal from './components/LoadStorageModal/LoadStorageModal';
import OffloadingModal from './components/OffloadingModal';
import SealStorageModal from './components/SealStorageModal/SealStorageModal';

type Props = {
  storage: Storage
  loading?: Loading
  onUpdate: () => void
};

const RightAlignedButton = styled(Button)`
  float: right;
  margin-left: ${spacing(1)};
`;

function StorageControls({
  storage, loading, onUpdate,
}: Props) {
  const [loadModalOpen, setLoadModalOpen] = useState(false);
  const [sealModalOpen, setSealModalOpen] = useState(false);
  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const [consolidateModalOpen, setConsolidateModalOpen] = useState(false);
  const [offloadingModalOpen, setOffloadingModalOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const canLoad = !loading;
  const canSeal = !canLoad && !loading?.seal;
  const canOffload = !canLoad && loading.seal;
  const canEmbed = storage.storage_type !== 'container' && loading && !loading?.offloading;

  return (
    <>
      { canEmbed && loading && (
        <>
          <RightAlignedButton onClick={() => setEmbedModalOpen(true)}>{t('embed')}</RightAlignedButton>
          <Form>
            <EmbedModal
              id={loading!.id}
              open={embedModalOpen}
              vesselId={loading.vessel_id}
              onCancel={() => setEmbedModalOpen(false)}
              onConfirm={() => { setEmbedModalOpen(false); navigate('/storage'); }}
            />
          </Form>
        </>
      )}
      {loading && canSeal && storage.storage_type === 'container' && (
        <>
          <RightAlignedButton onClick={() => setConsolidateModalOpen(true)}>{t('consolidate')}</RightAlignedButton>
          <Form options={{
            defaultValues: {
              from_loading_id: loading.id,
            },
          }}
          >
            <ConsolidateModal
              open={consolidateModalOpen}
              currentLoadingId={loading.id}
              vesselId={loading.vessel_id}
              onCancel={() => setConsolidateModalOpen(false)}
              onConfirm={() => { setConsolidateModalOpen(false); onUpdate(); }}
            />
          </Form>
        </>
      )}

      { canLoad && (
        <>
          <RightAlignedButton onClick={() => setLoadModalOpen(true)}>{t('load')}</RightAlignedButton>
          <Form options={{
            defaultValues: {
              storage_id: storage.id,
              port_name: 'Victoria BC, Canada',
            },
          }}
          >
            <LoadStorageModal
              open={loadModalOpen}
              onCancel={() => setLoadModalOpen(false)}
              onConfirm={() => { setLoadModalOpen(false); onUpdate(); }}
            />
          </Form>
        </>
      )}

      { canSeal && (
        <>
          <RightAlignedButton onClick={() => setSealModalOpen(true)}>{t('seal')}</RightAlignedButton>
          <Form options={{
            defaultValues: { loading_id: loading.id },
          }}
          >
            <SealStorageModal
              open={sealModalOpen}
              sealing_method={storage.container_type?.sealing_method || 'regular'}
              onCancel={() => setSealModalOpen(false)}
              onConfirm={() => { setSealModalOpen(false); onUpdate(); }}
            />
          </Form>
        </>
      )}

      { canOffload && (
        <>
          <RightAlignedButton onClick={() => setOffloadingModalOpen(true)}>{t('offload')}</RightAlignedButton>
          <Form options={{
            defaultValues: {
              loading_id: loading?.id,
              port_name: 'Victoria BC, Canada',
            },
          }}
          >
            <OffloadingModal
              loadingId={loading?.id}
              open={offloadingModalOpen}
              onCancel={() => setOffloadingModalOpen(false)}
              onConfirm={() => { setOffloadingModalOpen(false); onUpdate(); }}
            />
          </Form>
        </>
      )}

    </>
  );
}

export default StorageControls;
