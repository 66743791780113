import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { Table } from 'toc-styled-components';
import api from '../../../../lib/api';
import { toCamelCase } from '../../../../lib/helpers';
import { LoadingSummary, Storage } from '../../../../lib/types';
import StorageTableHistoryRow
  from './components/StorageHistoryTableRow/StorageHistoryTableRow';

type Props = {
  storage: Storage
};

function StorageHistory({ storage }: Props) {
  const { t } = useTranslation();
  const [history, setHistory] = useState<LoadingSummary[]>([]);
  const [loading, setLoading] = useState(true);

  const historyKeys = ['loaded_at', 'port_name', 'offloaded_port_name', 'vessel_name', 'status',
    'offloaded_at', 'embedded_at', 'weight'];

  const historyTable = (data: LoadingSummary[]) => (
    <Table loading={loading} overflowy="auto" fullWidth active>
      <tbody>
        <tr>
          {historyKeys.map((key) => <th key={key}>{t(toCamelCase(key))}</th>)}
        </tr>
        {data.map((s) => (
          <StorageTableHistoryRow key={s.id} storageId={storage.id.toString()} {...s} />
        ))}
      </tbody>
    </Table>
  );

  const getHistory = useCallback(() => {
    setLoading(true);
    api.get(['storage', storage.id.toString(), 'loadings']).then((response) => {
      setHistory(response.data);
    }).finally(() => setLoading(false));
  }, [storage]);

  useEffect(() => {
    getHistory();
  }, [getHistory, storage]);

  return (
    <>
      {storage && history.length > 0 && historyTable(history)}
      {history.length === 0 && !loading && (
        <div>No history found</div>
      )}
    </>
  );
}

export default StorageHistory;
