import styled from 'styled-components';
import { breakpoints, spacing } from 'toc-styled-components';

export const Container = styled.div`
  display: flex;
  align-items: end;
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: start;
  }
`;

export const ButtonContainer = styled.div`
  margin: ${spacing(1)} 0;
  @media (min-width: ${breakpoints.sm}) {
    padding-left: ${spacing(1)};
  }
`;
