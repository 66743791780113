import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  children: ReactNode
  options?: object
};

function Form({ children, options }: Props) {
  const methods = useForm({ mode: 'onChange', ...options });

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  );
}

export default Form;
