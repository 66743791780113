import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal, useAlert, Input, ErrorMessage, Button,
} from 'toc-styled-components';
import { Props as ModalProps } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../../../lib/api';
import { LoadingContents } from '../../../../../../lib/types';
import LoadingIdSelect
  from '../../../../../Form/LoadingIdSelect/LoadingIdSelect';
import WasteTypeSelect
  from '../../../../../Form/WasteTypeSelect';

type Props = { vesselId?: number, currentLoadingId?:number } & ModalProps;

function ConsolidateModal({
  onConfirm, vesselId, currentLoadingId, ...props
}: Props) {
  const {
    register, setValue, getValues, formState: { errors, isValid }, watch,
  } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();
  const [contents, setContents] = useState <LoadingContents>();
  const watchKG = watch('kg');
  const watchPackagingKG = watch('packaging_kg');
  const watchWasteType = watch('waste_type');

  useEffect(
    () => setValue('netto_kg', watchKG - watchPackagingKG, { shouldValidate: true }),
    [watchKG, watchPackagingKG, setValue, watchWasteType],
  );

  const consolidate = () => {
    api.post(['consolidate'], getValues()).then(() => {
      onConfirm?.();
      alert({ message: t('completedSuccessfully') });
    });
  };

  const validateContents = (v : string) => {
    const wasteType : string = getValues('waste_type');
    const currentContent = (contents!.totals as any)[wasteType] || 0;
    return Number(v) <= currentContent ? true : t('lessContent');
  };

  const getContents = useCallback(() => {
    api.get(['loadings', currentLoadingId!.toString(), 'contents']).then((response) => {
      setContents(response.data);
    });
  }, [currentLoadingId]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <Modal
      {...props}
      footer={(
        <Button disabled={!isValid} onClick={consolidate}>
          {t('consolidate')}
        </Button>
      )}
    >
      <LoadingIdSelect
        currentLoadingId={currentLoadingId}
        vesselId={vesselId}
        label={t('consolidateTo')}
        {...register('to_loading_id')}
        q="storage.storage_type=container"
        showAddBag={false}
      />
      <WasteTypeSelect />
      <Input
        {...register('kg', {
          required: { value: true, message: t('fieldRequired') },
        })}
        required
        label={t('catchKG')}
        type="number"
        min="0"
        step="1"
        error={!!errors.kg}
        tooltip={t('toolTipKG')}
      />
      <ErrorMessage message={errors.kg && String(errors.kg?.message)} />
      <Input
        {...register('packaging_kg', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= 0 ? true : t('positiveField')) },
        })}
        required
        label={t('packagingKg')}
        type="number"
        min="0"
        step="1"
        error={!!errors.packaging_kg}
        tooltip={t('toolTipPackagingKG')}
      />
      <Input
        {...register('netto_kg', { validate: { lessContent: validateContents } })}
        label={t('nettoKg')}
        readOnly
        type="number"
        error={!!errors.netto_kg}
        tooltip={t('toolTipNettoKG')}
      />
      <ErrorMessage message={errors.netto_kg && String(errors.netto_kg?.message)} />
    </Modal>
  );
}

export default ConsolidateModal;
