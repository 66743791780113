import styled from 'styled-components';
import {
  borderRadius, breakpoints, colors, spacing,
} from 'toc-styled-components';

export const DragAndDropArea = styled.div`
  border-radius: ${borderRadius};
  background: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  margin-top: ${spacing(0.5)};
  margin-bottom: ${spacing(2)};
  padding: ${spacing(2)};
`;

export const DragTarget = styled.div`
  border-radius: ${borderRadius};
  padding: ${spacing(3)};
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.04);
`;

export const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing(2)};
  margin-top: ${spacing(2)};
  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

type ThumbnailContainerProps = {
  url?: string
};

export const ThumbnailContainer = styled.a<ThumbnailContainerProps>`
  display: flex;
  align-items: center;
  background-image: url("${(props) => props.url}");
  background-size: cover;
  height: 100px;
  border-radius: ${borderRadius};
  padding: ${spacing(1)};
  display: flex;
  justify-content: end;
  align-items: end;
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  color: white;
  border-radius: ${borderRadius};
  padding: ${spacing(1)};
  background: rgba(0, 0, 0, .25);
  :hover {
    color: ${colors.red};
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DescriptionContainer = styled.div`
  border-radius: ${borderRadius};
  background: ${colors.lightGrey};
  padding: ${spacing(2)};
  margin: ${spacing(1)} 0;
  p {
    margin-top: 0;
  }
  ol {
    padding-left: ${spacing(2)};
    margin-bottom: 0;
    li {
      margin-bottom: ${spacing(0.75)};
    }
  }
`;
