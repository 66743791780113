import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal, useAlert,
} from 'toc-styled-components';
import { Props as ModalProps } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../lib/api';
import WasteDistributionFields from '../WasteDistributionTableRow/components/WasteDistributionFields';

type Props = { extractionId?: number, vesselId?:number } & ModalProps;

function AddDistributionModal({
  onConfirm, extractionId, vesselId, ...props
}: Props) {
  const { getValues, formState, reset } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();

  const addWeightDistribution = () => {
    api.post(['extractions', extractionId!.toString(), 'waste_distributions'], getValues()).then(() => {
      alert({ message: t('actionSuccessful') });
      onConfirm?.();
      reset();
    });
  };

  return (
    <Modal
      {...props}
      footer={(
        <Button disabled={!formState.isValid} onClick={addWeightDistribution}>
          {t('add')}
        </Button>
      )}
    >
      <WasteDistributionFields showAddBag vesselId={vesselId} />
    </Modal>
  );
}

export default AddDistributionModal;
