import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { Select } from 'toc-styled-components';

function PortSelect() {
  const { register } = useFormContext();

  return (
    <Select
      {...register('port_name', { required: true })}
      required
      label={t('portName')}
    >
      <option value="Victoria BC, Canada">Victoria BC, Canada</option>
    </Select>
  );
}

export default PortSelect;
