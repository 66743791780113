import styled from 'styled-components';
import { WasteDistribution } from '../../../../lib/types';
import CRUDControls from '../../../CRUDControls/CRUDControls';
import WasteDistributionFields from './components/WasteDistributionFields';
import { ButtonContainer } from './layout';
import { parseNumber } from '../../../../lib/helpers';

type Props = { onUpdate?: () => void, vesselId:number } & WasteDistribution;

const StyledCRUDControls = styled(CRUDControls)`
  margin: 0;
`;

export default function WasteDistributionTableRow({
  id, loading_id, vesselId, identifier, packaging_kg, kg, netto_kg, waste_type, read_only, onUpdate,
}: Props) {
  return (
    <tr>
      <td>{identifier}</td>
      <td>{parseNumber(kg)}</td>
      <td>{parseNumber(packaging_kg)}</td>
      <td>{parseNumber(netto_kg)}</td>
      <td>{waste_type}</td>
      <ButtonContainer>
        <StyledCRUDControls
          name={`extractions/${id}/waste_distributions`}
          editFields={<WasteDistributionFields vesselId={vesselId} />}
          resourceId={id}
          resource={{
            loading_id, kg, netto_kg, waste_type, packaging_kg,
          }}
          onUpdate={onUpdate}
          disableDelete={read_only}
          disableEdit={read_only}
          onDeleteUpdate={onUpdate}
        />
      </ButtonContainer>
    </tr>
  );
}
