import { t } from 'i18next';
import {
  createElement, ReactNode, useState,
} from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import {
  colors, Modal, spacing, useAlert,
} from 'toc-styled-components';
import api from '../../lib/api';
import Form from '../Form/Form';
import ResourceEditModal from './components/ResourceEditModal';

export const HeaderIcon = styled.span`
  cursor: pointer;
  margin-left: ${spacing(1)};
  &:hover {
    color: ${colors.primary};
  }
`;

type Props = {
  resourceId: number
  resource: object
  name: string
  editFields: ReactNode
  onUpdate?: () => void
  onDeleteUpdate?: () => void
  disableEdit?: boolean
  disableDelete?: boolean
};

function CRUDControls({
  resource, name, onUpdate, onDeleteUpdate, resourceId, editFields, disableEdit,
  disableDelete,
}: Props) {
  const { alert } = useAlert();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteResource = () => {
    api.delete([name, resourceId.toString()]).then(() => {
      setDeleteModalOpen(false);
      alert({ message: t('deleted') });
      onDeleteUpdate?.();
    });
  };

  const editResource = (values: object) => {
    api.put([name, resourceId.toString()], values).then(() => {
      setEditModalOpen(false);
      alert({ message: t('completedSuccessfully') });
      onUpdate?.();
    });
  };

  return (
    <>
      {!disableEdit && (
      <HeaderIcon>
        <MdEdit size={22} title="Edit" onClick={() => setEditModalOpen(true)} />
      </HeaderIcon>
      )}
      <Form options={{ defaultValues: resource }}>
        {createElement(ResourceEditModal, {
          open: editModalOpen,
          onConfirm: (values) => editResource(values!),
          onCancel: () => setEditModalOpen(false),
          confirmLabel: t('submit'),
          footer: <span />,
        }, editFields)}
      </Form>
      {!disableDelete && (
      <HeaderIcon>
        <MdDelete size={22} title="Undo" onClick={() => setDeleteModalOpen(true)} />
      </HeaderIcon>
      )}
      <Modal
        variant="danger"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => deleteResource()}
        confirmLabel={t('delete')}
      >
        {t('confirmDelete')}
      </Modal>
    </>
  );
}

export default CRUDControls;
