import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { Header as TOCHeader, HeaderLink } from 'toc-styled-components';
import VesselHeaderLinks from './components/VesselHeaderLinks';
import { HeaderLinkContainer, HeaderLinkContainerInner } from './layout';

function Header() {
  const { t } = useTranslation();

  return (
    <TOCHeader homeLinkAs={Link}>
      <HeaderLinkContainer>
        <HeaderLinkContainerInner>
          <HeaderLink as={NavLink} to="/storage">
            {t('containerLink')}
          </HeaderLink>
          <VesselHeaderLinks />
          <HeaderLink as={NavLink} to="/extractions">
            {t('extractions')}
          </HeaderLink>
        </HeaderLinkContainerInner>
      </HeaderLinkContainer>
    </TOCHeader>
  );
}

export default Header;
