import {
  forwardRef, Ref, useCallback, useEffect, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Button, Select, spacing,
} from 'toc-styled-components';
import { Props as SelectProps } from 'toc-styled-components/src/components/Select/Select';
import api from '../../../lib/api';
import { Loading } from '../../../lib/types';
import Form from '../Form';
import AddBagModal from './components/AddBagModal';

type Props = {
  showAddBag?: boolean;
  vesselId? :number;
  q?: string;
  currentLoadingId?: number;
} & SelectProps;

const Container = styled.div`
  display: flex;
  align-items: center;
  > button {
    margin-bottom: -9px;
    margin-left: ${spacing(1)};
  }
`;

function LoadingIdSelect({
  showAddBag, currentLoadingId, vesselId, q, ...props
}: Props, ref: Ref<any>) {
  const [loading, setLoading] = useState<Loading[]>([]);
  const [loadingState, setLoadingState] = useState(true);
  const [addBagModalOpen, setAddBagModalOpen] = useState(false);
  const { t } = useTranslation();
  const noResults = !loading.length && !loadingState;
  const { setValue, getValues } = useFormContext();
  const message = noResults ? t('noLoadingsForThisVessel') : t('pleaseSelect');

  const getLoading = useCallback(() => {
    const query = q ? (`status=loaded,vessel_id=${vesselId},${q}`) : (`status=loaded,vessel_id=${vesselId}`);

    api.get(['loadings'], {
      params: { q: query },
    }).then((response) => {
      const loadings = response.data.filter((l: Loading) => l.id !== currentLoadingId);
      setLoading(loadings);
      setLoadingState(false);
    });
  }, [vesselId, q, currentLoadingId]);

  useEffect(() => {
    getLoading();
  }, [getLoading]);

  const setSelectOption = (selected_id: number) => setValue(props.name!, selected_id);

  useEffect(() => {
    setValue(props.name!, getValues(props.name!));
  }, [loading, props.name, getValues, setValue]);

  return (
    <Container>
      <div>
        <Select required disabled={loadingState || noResults} ref={ref} {...props}>
          <option value="">{message}</option>
          {loading.map((l) => (
            <option key={l.id} value={l.id}>
              {l.storage.identifier}
            </option>
          ))}
        </Select>
      </div>
      { showAddBag && (
      <>
        <Button onClick={() => setAddBagModalOpen(true)}>{t('addBag')}</Button>
        <Form options={{ defaultValues: { vessel_id: vesselId, storage_type: 'bag', tare_weight: 0 } }}>
          <AddBagModal
            open={addBagModalOpen}
            onCancel={() => setAddBagModalOpen(false)}
            onConfirm={() => { setAddBagModalOpen(false); getLoading(); }}
            setSelectOption={setSelectOption}
          />
        </Form>
      </>
      ) }
    </Container>
  );
}

export default forwardRef(LoadingIdSelect);
