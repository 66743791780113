import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Table } from 'toc-styled-components';
import { useParams } from 'react-router-dom';
import api from '../../lib/api';
import StorageTableRow from './components/StorageTableRow/StorageTableRow';
import { Storage, Vessel } from '../../lib/types';
import { vesselContext } from '../VesselContext/VesselContext';

function VesselStorage() {
  const { t } = useTranslation();
  const { vesselId } = useParams();
  const vessels = useContext<Vessel[]>(vesselContext);
  const [locationName, setLocationName] = useState('');
  const [storage, setStorage] = useState<Storage[]>([]);
  const [loading, setLoading] = useState(true);

  const storageTable = (data: Storage[]) => (
    <Table loading={loading} overflowy="auto" fullWidth active>
      <tbody>
        <tr>
          <th>{t('identifier')}</th>
          <th>{t('location')}</th>
          <th>{t('tareWeight')}</th>
          <th>{t('storageType')}</th>
        </tr>
        {data.map((s) => (
          <StorageTableRow key={s.id} {...s} />
        ))}
      </tbody>
    </Table>
  );

  const getStorage = useCallback(() => {
    setLoading(true);

    const vessel = vessels.find((obj: any) => obj.id === parseInt(vesselId!, 10));
    if (vessel) { setLocationName(vessel.name); } else { setLocationName('Unknown'); }

    api.get(['storage'], { params: { q: `loading.vessel_id=${vesselId}` } }).then((response) => {
      setStorage(response.data);
    }).finally(() => setLoading(false));
  }, [vesselId, vessels]);

  useEffect(() => {
    getStorage();
  }, [getStorage, vesselId]);

  return (
    <Card>
      {storage.length > 0 && (
        <>
          <h2>{`${t('Storage')} ${locationName}`}</h2>
          {storageTable(storage)}
        </>
      )}
      {storage.length === 0 && !loading && (
        <div>No storage found</div>
      )}
    </Card>
  );
}

export default VesselStorage;
