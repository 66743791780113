import {
  forwardRef, Ref, useEffect, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from 'toc-styled-components';
import { Props } from 'toc-styled-components/lib/components/Select/Select';
import api from '../../../../../../lib/api';
import { ContainerType } from '../../../../../../lib/types';
import { Container } from './layout';

function StorageTypeSelect({ label, required, ...props }: Props, ref: Ref<Props>) {
  const { t } = useTranslation();
  const [containerTypes, setStorageTypes] = useState<ContainerType[]>([]);
  const { setValue, getValues } = useFormContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(['storage', 'container_types']).then((response) => {
      setStorageTypes(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setValue(props.name!, getValues(props.name!));
  }, [containerTypes, setValue, getValues, props.name]);

  return (
    <Container>
      <div>
        <Label required={required}>{label}</Label>
        <Select disabled={loading} ref={ref} {...props}>
          {loading && <option>{`${t('loading')}...`}</option>}
          <option value="">{t('selectType')}</option>
          {containerTypes.map((type) => (
            <option key={type.id} value={type.id}>{`${type.code} - ${type.sealing_method}`}</option>
          ))}
        </Select>
      </div>
    </Container>
  );
}

export default forwardRef(StorageTypeSelect);
