import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Table } from 'toc-styled-components';
import api from '../../lib/api';
import Form from '../Form/Form';
import AddButton from './components/AddButton/AddButton';
import AddContainerModal from './components/AddContainerModal/AddContainerModal';
import ContainerTableRow from './components/ContainerTableRow/ContainerTableRow';
import { Storage as StorageType } from '../../lib/types';

function Storage() {
  const { t } = useTranslation();
  const [storage, setStorage] = useState<StorageType[]>([]);
  const [loading, setLoading] = useState(true);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const storageTable = (data: StorageType[]) => (
    <Table loading={loading} overflowy="auto" fullWidth active>
      <tbody>
        <tr>
          <th>{t('identifier')}</th>
          <th>{t('location')}</th>
          <th>{t('tareWeight')}</th>
          <th>{t('containerType')}</th>
        </tr>
        {data.map((s) => (
          <ContainerTableRow key={s.id} {...s} />
        ))}
      </tbody>
    </Table>
  );

  const getStorage = useCallback(() => {
    setLoading(true);
    api.get(['storage'], { params: { q: 'storage_type=container' } }).then((response) => {
      setStorage(response.data);
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getStorage();
  }, [getStorage]);

  const addStorage = () => {
    setAddModalOpen(true);
  };

  return (
    <Card>
      <AddButton onClick={addStorage}>{t('addContainer')}</AddButton>
      {storage.length > 0 && (
        <>
          <h2>{t('AllContainers')}</h2>
          {storageTable(storage)}
        </>
      )}
      {storage.length === 0 && !loading && (
        <h2>No storage found</h2>
      )}

      <Form options={{ defaultValues: { storage_type: 'container' } }}>
        <AddContainerModal
          onCancel={() => setAddModalOpen(false)}
          open={addModalOpen}
          onConfirm={() => { setAddModalOpen(false); getStorage(); }}
        />
      </Form>
    </Card>
  );
}

export default Storage;
