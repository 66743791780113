import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { Input } from 'toc-styled-components';
import { useEffect } from 'react';

function HarvestingSince() {
  const { register, unregister } = useFormContext();

  useEffect(() => () => {
    unregister('harvesting_since');
  }, [unregister]);

  return (
    <Input
      {...register('harvesting_since', { required: true })}
      label={t('harvestingSince')}
      type="datetime-local"
      required
      tooltip={t('toolTipExtractionHarvestingSince')}
    />
  );
}

export default HarvestingSince;
