import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, useAlert } from 'toc-styled-components';
import Modal, { Props as ModalProps } from 'toc-styled-components/lib/components/Modal/Modal';
import api from '../../../../../../lib/api';
import SealStorageFields from './components/SealStorageFields';

type Props = {
  sealing_method : string
} & ModalProps;

function SealStorageModal({ onConfirm, sealing_method, ...props }: Props) {
  const { formState, getValues } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();

  const editStorage = () => {
    const formValues = getValues();
    api.post(['seals'], formValues).then(() => {
      onConfirm?.();
      alert({ message: t('completedSuccessfully') });
    });
  };

  return (
    <Modal
      {...props}
      footer={(
        <Button
          disabled={!formState.isValid}
          onClick={editStorage}
        >
          {t('submit')}
        </Button>
    )}
    >
      <SealStorageFields sealing_method={sealing_method} />
    </Modal>
  );
}

export default SealStorageModal;
