import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Input } from 'toc-styled-components';
import StorageTypeSelect
  from '../../../../Storage/components/AddContainerModal/components/StorageTypeSelect/StorageTypeSelect';

function EditStorageFields() {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();
  return (
    <>
      <Input
        {...register('identifier', { required: { value: true, message: t('fieldRequired') } })}
        required
        label={t('identifier')}
        error={!!errors.identifier}
        tooltip={t('toolTipContainerIdentifier')}
      />
      <ErrorMessage message={errors.identifier && String(errors.identifier?.message)} />
      <Input
        {...register('tare_weight', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= 0 ? true : t('positiveField')) },
        })}
        required
        label={`${t('tareWeight')} (kg)`}
        type="number"
        min="0"
        step="1"
        error={!!errors.tare_weight}
        tooltip={t('toolTipContainerTareWeight')}
      />
      <ErrorMessage message={errors.tare_weight && String(errors.tare_weight?.message)} />
      <StorageTypeSelect
        {...register('container_type_id', { required: true })}
        required
        label={t('containerType')}
      />
    </>
  );
}

export default EditStorageFields;
