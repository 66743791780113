import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Table, Tooltip } from 'toc-styled-components';
import api from '../../lib/api';
import { filterObject } from '../../lib/helpers';
import { Extraction, WasteDistribution } from '../../lib/types';
import { InlineH2, StyledH2 } from '../../shared/layout';
import CRUDControls from '../CRUDControls/CRUDControls';
import DataParserTable from '../DataParserTable/DataParserTable';
import ExtractionFields from '../Extractions/components/AddExtractionModal/components/ExtractionFields';
import Form from '../Form/Form';
import AddButton from '../Storage/components/AddButton/AddButton';
import AddDistributionModal from './components/AddDistributionModal/AddDistributionModal';
import WasteDistributionTableRow from './components/WasteDistributionTableRow/WasteDistributionTableRow';

function ExtractionDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [extraction, setExtraction] = useState<Extraction>();
  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(true);
  const [distributionModalOpen, setDistributionModalOpen] = useState(false);
  const extractionKeys = ['harvesting_since', 'started_at', 'method'];
  const extractionEditKeys = extractionKeys.concat(['vessel_id', 'name']);
  const navigate = useNavigate();

  const getExtraction = useCallback(() => {
    setLoading(true);
    api.get(['extractions', id!.toString()]).then((response) => {
      setExtraction(response.data);
      const newTotals : { [waste_type: string] : number } = {};
      response.data.waste_distributions.forEach((d: WasteDistribution) => {
        newTotals[d.waste_type] = (newTotals[d.waste_type] || 0) + d.netto_kg;
      });
      newTotals.Total = Object.values(newTotals).reduce((a, b) => a + b, 0);
      setTotals(newTotals);
    }).finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    getExtraction();
  }, [getExtraction]);

  return (
    <Card>
      <InlineH2>{`${t('extraction')} ${extraction?.name}`}</InlineH2>
      {!!extraction && (
        <CRUDControls
          name="extractions"
          editFields={<ExtractionFields />}
          resourceId={extraction.id}
          resource={filterObject(extraction || {}, extractionEditKeys)}
          disableDelete={extraction.waste_distributions.length > 0}
          onDeleteUpdate={() => navigate('/extractions')}
          onUpdate={() => getExtraction()}
        />
      )}
      <DataParserTable loading={loading} data={filterObject(extraction || {}, extractionKeys)} />

      <DataParserTable loading={loading} data={totals} title="Totals (kg)" />

      <AddButton onClick={() => setDistributionModalOpen(true)}>{t('addDistribution')}</AddButton>
      <Form>
        <AddDistributionModal
          extractionId={extraction?.id}
          vesselId={extraction?.vessel_id}
          open={distributionModalOpen}
          onCancel={() => setDistributionModalOpen(false)}
          onConfirm={() => { getExtraction(); setDistributionModalOpen(false); }}
        />
      </Form>
      {extraction && extraction.waste_distributions?.length > 0
        && (
        <>
          <StyledH2>
            {t('wasteDistribution')}
            <Tooltip>{t('wasteDistributionDescription')}</Tooltip>
          </StyledH2>
          <Table fullWidth overflowy="auto" loading={loading}>
            <tbody>
              <tr>
                <th>{t('storageIdentifier')}</th>
                <th>{t('grossWeight')}</th>
                <th>{t('packaging')}</th>
                <th>{t('nettoKg')}</th>
                <th>{t('wasteType')}</th>
                <th> </th>
              </tr>
              {extraction.waste_distributions.map((distro) => (
                <WasteDistributionTableRow
                  onUpdate={getExtraction}
                  vesselId={extraction?.vessel_id}
                  key={distro.id}
                  {...distro}
                />
              ))}
            </tbody>
          </Table>
        </>
        )}
    </Card>
  );
}

export default ExtractionDetails;
