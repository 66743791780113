import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, useAlert } from 'toc-styled-components';
import Modal from 'toc-styled-components/lib/components/Modal/Modal';
import api from '../../../../../../lib/api';
import { ResourceEditModalProps } from '../../../../../CRUDControls/components/ResourceEditModal';
import LoadStorageFields from './components/LoadStorageFields';

type Props = ResourceEditModalProps;

function LoadStorageModal({
  onConfirm, footer, ...props
}: Props) {
  const { formState, getValues } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();

  const loadStorage = () => {
    api.post(['loadings'], getValues()).then(() => {
      onConfirm?.();
      alert({ message: t('completedSuccessfully') });
    });
  };

  return (
    <Modal
      {...props}
      footer={(
        <Button disabled={!formState.isValid} onClick={loadStorage}>
          {t('load')}
        </Button>
      )}
    >
      <LoadStorageFields />
    </Modal>
  );
}

export default LoadStorageModal;
