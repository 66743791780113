import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'toc-styled-components';
import { useEffect } from 'react';
import LoadingIdSelect from '../../../../Form/LoadingIdSelect/LoadingIdSelect';
import WasteTypeSelect from '../../../../Form/WasteTypeSelect';

type Props = {
  showAddBag?: boolean
  vesselId?: number
};

function WasteDistributionFields({ showAddBag, vesselId }: Props) {
  const {
    register, setValue, formState: { errors }, watch,
  } = useFormContext();
  const { t } = useTranslation();

  const watchKG = watch('kg');
  const watchPackagingKG = watch('packaging_kg');

  useEffect(() => setValue('netto_kg', watchKG - watchPackagingKG), [watchKG, watchPackagingKG, setValue]);

  return (
    <>
      <LoadingIdSelect
        label={t('catchLoading')}
        {...register('loading_id')}
        showAddBag={showAddBag}
        vesselId={vesselId}
      />
      <Input
        {...register('kg', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= 0 ? true : t('positiveField')) },
        })}
        required
        label={t('catchKG')}
        type="number"
        min="0"
        step="1"
        error={!!errors.kg}
        tooltip={t('toolTipKG')}
      />
      <Input
        {...register('packaging_kg', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= 0 ? true : t('positiveField')) },
        })}
        required
        label={t('packagingKg')}
        type="number"
        min="0"
        step="1"
        error={!!errors.packaging_kg}
        tooltip={t('toolTipPackagingKG')}
      />
      <Input
        {...register('netto_kg')}
        label={t('nettoKg')}
        readOnly
        type="number"
        tooltip={t('toolTipNettoKG')}
      />
      <WasteTypeSelect />
    </>
  );
}

export default WasteDistributionFields;
