import { t } from 'i18next';
import { Table } from 'toc-styled-components';
import { Props as TableProps } from 'toc-styled-components/src/components/Table/Table';
import { toCamelCase } from '../../lib/helpers';
import DataParser from '../DataParser/DataParser';

type Props = {
  data: object
  title?: string
} & Omit<TableProps, 'children'>;

function DataParserTable({
  data, title, overflowy = 'auto', ...props
}: Props) {
  return (
    <>
      {title && <h2>{title}</h2>}
      <Table overflowy={overflowy} {...props}>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <th>{t(toCamelCase(key))}</th>
              <td><DataParser name={key} value={value} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default DataParserTable;
