import { useNavigate } from 'react-router-dom';
import { Storage } from '../../../../lib/types';

type Props = Storage;

function StorageTableRow({
  id, identifier, tare_weight, storage_type, current_loading_id, current_vessel,
}: Props) {
  const navigate = useNavigate();

  return (
    <tr onClick={() => navigate(`/storage/${id}/details/${current_loading_id || ''}`)}>
      <td>{identifier}</td>
      <td>{ current_vessel || 'Shore'}</td>
      <td>{tare_weight}</td>
      <td>{storage_type}</td>
    </tr>
  );
}

export default StorageTableRow;
