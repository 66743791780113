import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';

// Greetings, future code wizard! 🧙🏼
// Once you've fully translated the indonesian JSON file (id.json),
// uncomment the commented lines in this file and the language switcher will appear.

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
  },
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

export default i18n;
