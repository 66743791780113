import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'toc-styled-components';
import ImageUpload from '../../../../../../ImageUpload/ImageUpload';

type Props = {
  sealing_method: string
};

function SealStorageFields({ sealing_method }: Props) {
  const { t } = useTranslation();
  const { register } = useFormContext();

  const isOpenTopContainer = sealing_method === 'open-top';

  return (
    <>
      <ImageUpload
        {...register('pictures', { minLength: 1, required: true })}
        label={t('sealPictures')}
        required
      />
      <Input
        {...register('approx_fill_level', { required: true })}
        type="number"
        required
        step="10"
        min="0"
        max="100"
        label={t('approxFillLevel')}
        tooltip={t('toolTipApproxFillLevel')}
      />
      <Input
        {...register('seal_code', { required: true })}
        type="string"
        required
        label={t('sealCode')}
        tooltip={t('toolTipSealCode')}
      />
      {isOpenTopContainer && (
        <Input
          {...register('seal_code_top', { required: true })}
          type="string"
          required
          label={t('sealCodeTop')}
          tooltip={t('toolTipSealCodeTop')}
        />
      )}
      <Input
        {...register('sealed_at', { required: true })}
        type="datetime-local"
        required
        label={t('sealedAt')}
        tooltip={t('toolTipSealedAt')}
      />
    </>
  );
}

export default SealStorageFields;
