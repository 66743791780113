import { useFormContext } from 'react-hook-form';
import { Select } from 'toc-styled-components';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Vessel } from '../../lib/types';
import { vesselContext } from '../VesselContext/VesselContext';

function VesselSelect() {
  const { register } = useFormContext();
  const vessels = useContext<Vessel[]>(vesselContext);
  const { t } = useTranslation();

  return (
    <Select
      {...register('vessel_id', { required: true })}
      required
      label={t('vesselSelect')}
    >
      {vessels.map((vessel) => (
        <option value={vessel.id}>{vessel.name}</option>
      ))}
    </Select>
  );
}

export default VesselSelect;
