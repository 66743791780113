import { useNavigate } from 'react-router-dom';
import { LoadingSummary } from '../../../../../../lib/types';

type Props = {
  storageId : string
} & LoadingSummary;

function StorageTableHistoryRow({
  id, storageId, loaded_at, port_name, offloaded_port_name, vessel_name,
  status, offloaded_at, embedded_at, weight,
}:Props) {
  const navigate = useNavigate();

  return (
    <tr onClick={() => navigate(`/storage/${storageId}/details/${id || ''}`)}>
      <td>{loaded_at}</td>
      <td>{port_name}</td>
      <td>{offloaded_port_name}</td>
      <td>{vessel_name}</td>
      <td>{status}</td>
      <td>{offloaded_at}</td>
      <td>{embedded_at}</td>
      <td>{weight}</td>
    </tr>
  );
}

export default StorageTableHistoryRow;
