import axios, { AxiosPromise } from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const createApiUrl = (urlFragments: string[]) => `${baseUrl}/${urlFragments.join('/')}`;

const apiCall = (
  urlFragments: string[],
  method: string,
  data?: object,
  args?: object,
): AxiosPromise => (axios as any)[method](createApiUrl(urlFragments), data, args);

const [get, post, patch, put, apiDelete] = [
  'get',
  'post',
  'patch',
  'put',
  'delete',
].map(
  (method: string) => (urlFragments: string[], data?: object, args?: object): AxiosPromise => (
    apiCall(urlFragments, method, data, args)
  ),
);

const api = {
  get,
  post,
  patch,
  put,
  delete: apiDelete,
};

export default api;
