import { Label, Loader } from 'toc-styled-components';
import { useTranslation } from 'react-i18next';
import { ForwardedRef, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import api from '../../lib/api';
import {
  DragAndDropArea, DragTarget, LoadingContainer, ThumbnailGrid,
} from './layout';
import ImageThumbnail from './components/ImageThumbnail';

type Props = {
  label: string
  required?: boolean
  name: string
};

// We accept ref here so this component plays nicely with `register` from `useFormContext()`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ImageUpload = forwardRef(({ label, name, required }: Props, ref :ForwardedRef<any>) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    setValue, watch, getValues,
  } = useFormContext();
  const [media] = watch([name]);

  const onDelete = (fileId: number) => {
    api.delete(['media', fileId.toString()]);
    setValue(name, media.filter((id: number) => id !== fileId));
  };

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file, file.name);
        api.post(['media'], formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => {
          const [mediaIds] = getValues([name]);
          setValue(name, [...(mediaIds || []), response.data.id], { shouldDirty: true, shouldValidate: true });
        }).finally(() => setLoading(false));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <Label required={required}>{label}</Label>
      <DragAndDropArea>
        <DragTarget {...getRootProps()}>
          <input {...getInputProps()} />
          <span>{t('dragAndDropLabel')}</span>
        </DragTarget>
        { (media?.length > 0 || loading) && (
        <ThumbnailGrid>
          {media?.map((mediaId: number) => (
            <ImageThumbnail key={mediaId} id={mediaId} onDelete={onDelete} />
          ))}
          { loading && <LoadingContainer><Loader variant="secondary" /></LoadingContainer>}
        </ThumbnailGrid>
        ) }
      </DragAndDropArea>
    </>
  );
});

export default ImageUpload;
