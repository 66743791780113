import axios from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'toc-styled-components';

function ErrorHandler() {
  const { alert } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const statusCode = error.response?.status;
        if ([401, 403].includes(statusCode)) {
          alert({ message: t('notAllowed'), variant: 'danger' });
        } else if (statusCode === 404) {
          alert({ message: t('notFound'), variant: 'danger' });
        } else {
          alert({ message: t('somethingWentWrong'), variant: 'danger' });
        }
        return Promise.reject(error);
      },
    );
  }, []); // eslint-disable-line

  return null;
}

export default ErrorHandler;
