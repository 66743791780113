import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { Select } from 'toc-styled-components';

function WasteTypeSelect() {
  const { register } = useFormContext();

  return (
    <Select
      {...register('waste_type', { required: true })}
      required
      label={t('wasteType')}
    >
      <option value="fibrous">{t('fibrous')}</option>
      <option value="unsorted">{t('unsorted')}</option>
      <option value="rigids">{t('rigids')}</option>
    </Select>
  );
}

export default WasteTypeSelect;
