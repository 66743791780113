import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal,
} from 'toc-styled-components';
import { Props } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../lib/api';
import ExtractionFields from './components/ExtractionFields';

function AddExtractionModal({ onConfirm, ...props }: Props) {
  const { getValues, formState, reset } = useFormContext();
  const { t } = useTranslation();

  const addStorage = () => {
    api.post(['extractions'], getValues()).then(() => {
      onConfirm?.();
      reset();
    });
  };

  return (
    <Modal
      {...props}
      footer={(
        <Button disabled={!formState.isValid} onClick={addStorage}>
          {t('add')}
        </Button>
      )}
    >
      <ExtractionFields />
    </Modal>
  );
}

export default AddExtractionModal;
