import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'toc-styled-components';
import { Props as ModalProps } from 'toc-styled-components/src/components/Modal/Modal';
import Modal from 'toc-styled-components/lib/components/Modal/Modal';

export type Props = {
  onConfirm: (values?: object) => void
} & Omit<ModalProps, 'onConfirm'>;

function ResourceEditModal({
  onConfirm, footer, children, ...props
}: Props) {
  const { formState, getValues } = useFormContext();
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      footer={(
        <Button
          disabled={!formState.isValid}
          onClick={() => onConfirm(getValues())}
        >
          {t('submit')}
        </Button>
      )}
    >
      {children}
    </Modal>
  );
}

export type ResourceEditModalProps = Props;
export default ResourceEditModal;
