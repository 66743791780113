import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, Modal, useAlert, ErrorMessage,
} from 'toc-styled-components';
import { Props } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../lib/api';
import StorageTypeSelect from './components/StorageTypeSelect/StorageTypeSelect';

function AddContainerModal({ onConfirm, ...props }: Props) {
  const {
    register, getValues, reset, formState: { isValid, errors },
  } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();

  const addStorage = () => {
    api.post(['storage'], getValues()).then(() => {
      onConfirm?.();
      reset();
    }).catch((response) => {
      if (response.response?.data?.detail === 'Key already exists') {
        alert({ message: t('identifierAlreadyExists'), variant: 'danger' });
      }
    });
  };

  return (
    <Modal
      {...props}
      footer={(
        <Button disabled={!isValid} onClick={addStorage}>
          {t('add')}
        </Button>
      )}
    >
      <Input
        {...register('identifier', { required: { value: true, message: t('fieldRequired') } })}
        required
        label={t('identifier')}
        error={!!errors.identifier}
        tooltip={t('toolTipContainerIdentifier')}
      />
      <ErrorMessage message={errors.identifier && String(errors.identifier?.message)} />
      <Input
        {...register('tare_weight', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= 0 ? true : t('positiveField')) },
        })}
        required
        label={`${t('tareWeight')} (kg)`}
        type="number"
        min="0"
        step="1"
        error={!!errors.tare_weight}
        tooltip={t('toolTipContainerTareWeight')}
      />
      <ErrorMessage message={errors.tare_weight && String(errors.tare_weight?.message)} />
      <StorageTypeSelect
        {...register('container_type_id', { required: true })}
        required
        label={t('containerType')}
      />
    </Modal>
  );
}

export default AddContainerModal;
