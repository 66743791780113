import { useNavigate } from 'react-router-dom';
import { Storage } from '../../../../lib/types';

type Props = Storage;

function ContainerTableRow({
  id, identifier, tare_weight, container_type, current_loading_id, current_vessel,
}: Props) {
  const navigate = useNavigate();

  return (
    <tr onClick={() => navigate(`/storage/${id}/details/${current_loading_id || ''}`)}>
      <td>{identifier}</td>
      <td>{ current_vessel || 'Shore'}</td>
      <td>{tare_weight}</td>
      <td>{container_type?.code}</td>
    </tr>
  );
}

export default ContainerTableRow;
