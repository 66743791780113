import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, useAlert, ErrorMessage,
} from 'toc-styled-components';
import Modal, { Props as ModalProps } from 'toc-styled-components/lib/components/Modal/Modal';
import api from '../../../../../lib/api';
import PortSelect from './PortSelect';
import ImageUpload from '../../../../ImageUpload/ImageUpload';

export const WarningMessage = styled(ErrorMessage)`
  color: #cd7300;
`;

type Props = {
  loadingId?: number
} & ModalProps;

function OffloadingModal({ onConfirm, loadingId, ...props }: Props) {
  const {
    register, watch, formState: { errors, isValid }, getValues,
  } = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlert();
  const watchWeight = watch('kg_offloaded_gross_weight');
  const [totalWeight, setTotalWeight] = useState<number>();
  const [tareWeight, setTotalTareWeight] = useState<number>();
  const [isDifferent, setIsDifferent] = useState<boolean>(false);

  const editStorage = () => {
    const formValues = getValues();
    api.post(['offloadings'], formValues).then(() => {
      onConfirm?.();
      alert({ message: t('completedSuccessfully') });
    });
  };

  useEffect(() => {
    api.get(['loadings', loadingId!.toString(), 'contents']).then((response) => {
      setTotalWeight(response.data.totals.gross_total);
      setTotalTareWeight(response.data.totals.tare_weight);
    });
  }, [loadingId]);

  useEffect(() => {
    const userWeight = parseInt(getValues('kg_offloaded_gross_weight'), 10);
    const dbWeight = totalWeight;
    setIsDifferent(Math.abs((userWeight - dbWeight!) / dbWeight!) > 0.2);
  }, [watchWeight, totalWeight, getValues]);

  return (
    <Modal
      {...props}
      footer={(
        <Button
          disabled={!isValid}
          onClick={editStorage}
        >
          {t('submit')}
        </Button>
    )}
    >
      <Input
        {...register('offloaded_at', { required: true })}
        type="datetime-local"
        required
        label={t('offloadedAt')}
        tooltip={t('toolTipOffloadedAt')}
      />
      <PortSelect />
      <Input
        label={t('estimatedOffloadedGrossWeight')}
        {...register('kg_estimated_offloaded_gross_weight')}
        type="number"
        readOnly
        defaultValue={totalWeight}
        tooltip={t('toolTipEstimatedOffloadedGrossWeight')}
      />
      <Input
        label={t('offloadedGrossWeight')}
        {...register('kg_offloaded_gross_weight', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= tareWeight! || 0 ? true : t('biggerThanTareWeight')) },
        })}
        required
        type="number"
        tooltip={t('toolTipoffloadedGrossWeight')}
        error={!!errors.kg_offloaded_gross_weight}
      />
      {totalWeight! > 0 && getValues('kg_offloaded_gross_weight') && isDifferent
        && <WarningMessage message={t('warningOffloadedWeight')} />}
      <ErrorMessage message={errors.kg_offloaded_gross_weight && String(errors.kg_offloaded_gross_weight?.message)} />
      <ImageUpload
        {...register('pictures', { minLength: 1, required: true })}
        label={t('offloadPictures')}
        required
      />
    </Modal>
  );
}

export default OffloadingModal;
