import { useTranslation } from 'react-i18next';
import { Card } from 'toc-styled-components';
import { filterObject } from '../../../../lib/helpers';

import { Loading, Storage } from '../../../../lib/types';
import { InlineH2 } from '../../../../shared/layout';
import CRUDControls from '../../../CRUDControls/CRUDControls';
import DataParserTable from '../../../DataParserTable/DataParserTable';
import LoadStorageFields from '../StorageControls/components/LoadStorageModal/components/LoadStorageFields';
import SealStorageFields from '../StorageControls/components/SealStorageModal/components/SealStorageFields';

type Props = {
  storage: Storage;
  loading?: Loading;
  setLoading: (loading?: Loading) => void;
  getStorage: () => void;
};

function StorageOverview({
  storage,
  loading, setLoading, getStorage,
}: Props) {
  const { t } = useTranslation();
  const loadingKeys = ['port_name', 'loaded_at', 'tare_weight_pictures', 'storage_pictures'];
  const sealKeys = ['sealed_at', 'approx_fill_level', 'pictures', 'seal_code', 'seal_code_top'];
  const offloadingKeys = ['offloaded_at', 'port_name', 'kg_offloaded_gross_weight', 'pictures'];

  // Either Redirect or force a reload to get rid of the loading object
  // const onLoadingDelete = () => (loading && loading.id
  //   ? navigate(`/storage/${storage.id}/details/`)
  //   : window.location.reload()
  // );
  const viewOnlyMode = loading && (['embedded', 'offloaded'].includes(loading?.status));

  if (!loading) return <Card>Container / Bag is currently not loaded</Card>;
  return (
    <>
      <InlineH2>{t('loading')}</InlineH2>
      {!viewOnlyMode && (
        <CRUDControls
          resourceId={loading.id}
          resource={{
            ...filterObject(loading, loadingKeys),
          }}
          editFields={<LoadStorageFields />}
          name="loadings"
          onUpdate={getStorage}
          disableDelete={loading.seal != null}
          onDeleteUpdate={() => setLoading(undefined)}
        />
      )}
      <DataParserTable
        data={{
          ...filterObject(loading, loadingKeys),
          vessel: loading.vessel.name,
        }}
      />
      {loading.seal && (
        <>
          <InlineH2>{t('seal')}</InlineH2>
          {!viewOnlyMode && (
          <CRUDControls
            resourceId={loading.seal.id}
            resource={filterObject(loading.seal, sealKeys)}
            editFields={<SealStorageFields sealing_method={storage.container_type?.sealing_method || 'regular'} />}
            name="seals"
            onUpdate={getStorage}
            disableDelete={loading.offloading != null}
            onDeleteUpdate={getStorage}
          />
          )}

          <DataParserTable
            data={filterObject(loading.seal, sealKeys)}
          />
        </>
      )}
      {loading.offloading && (
        <DataParserTable
          title={t('offloading')}
          data={filterObject(loading.offloading, offloadingKeys)}
        />
      )}
    </>
  );
}

export default StorageOverview;
