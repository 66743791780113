import i18n from '../locale';

export const objectFromArrays = (keys: string[], values: any[]) => {
  const object: any = {};
  keys.forEach((key: string, index: number) => {
    object[key] = values[index];
  });
  return object;
};

export const truncate = (s: string, limit?: number) => {
  if (!limit) return s;
  if (s.length > limit) {
    return `${s.slice(0, limit)}...`;
  }
  return s;
};

export const toCamelCase = (s: string) => (
  s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''))
);

export const capitalize = (s: string) => (
  s.charAt(0).toUpperCase() + s.slice(1)
);

export const toHumanDate = (date: string) => (
  // This function is expecting the following format: "yyyy-mm-ddThh:mm:ss"
  new Date(date).toLocaleString([], {
    year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
  })
);

export const isDate = (date: string) => (
  // Add an extra check to avoid numbers like 1 or 2000 to be converted to datetime
  !Number.isNaN(Date.parse(date)) && date.includes('-')
);

export const parseNumber = (n: number) => n.toLocaleString(navigator.language, { minimumFractionDigits: 0 });

export const parseData = (d: any, maxLength?: number) => {
  switch (typeof d) {
    case 'string': {
      if (isDate(d)) {
        return toHumanDate(d);
      }
      const translatedString = i18n.t(toCamelCase(d));
      if (translatedString === toCamelCase(d)) {
        return truncate(d, maxLength);
      }
      return truncate(translatedString, maxLength);
    }
    case 'number':
      return parseNumber(d);
    case 'object':
      if (Array.isArray(d)) {
        return d.join(', ');
      }
      return JSON.stringify(d);
    default:
      return d;
  }
};

export const filterObject = (obj: object, keys: string[]) => (
  Object.fromEntries(
    Object.entries(obj).sort(([a], [b]) => {
      const comparison = keys.indexOf(a) > keys.indexOf(b);
      return comparison ? 1 : -1;
    }).filter(([key]) => keys.includes(key)),
  )
);
