import styled from 'styled-components';
import { spacing } from 'toc-styled-components';

export const ButtonContainer = styled.td`
  display: flex;
  justify-content: end;
  align-items: center;
  > * > button {
    margin-right: 0;
    margin-left: ${spacing(1)};
  }
`;
