import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AlertProvider, GlobalStyle } from 'toc-styled-components';
import App from './App';
import AuthWrapper from './components/AuthWrapper/AuthWrapper';
import reportWebVitals from './reportWebVitals';
import './locale';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <AlertProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        redirectUri={window.location.origin}
        audience={
        process.env.REACT_APP_AUTH0_AUDIENCE
        || process.env.REACT_APP_BACKEND_BASE_URL
      }
      >
        <GlobalStyle />
        <ErrorHandler />
        <AuthWrapper>
          <App />
        </AuthWrapper>
      </Auth0Provider>
    </AlertProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
