import { ReactNode } from 'react';
import { RiAddFill } from 'react-icons/ri';
import styled from 'styled-components';
import { breakpoints, Button, spacing } from 'toc-styled-components';

type Props = {
  onClick?: () => void
  children?: ReactNode
};

export const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${spacing(4)};
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.sm}) {
    margin: ${spacing(3)};
  }
`;

function AddButton({ onClick, children }: Props) {
  return (
    <StyledButton onClick={(onClick)}>
      <RiAddFill size={20} />
      {children}
    </StyledButton>
  );
}

export default AddButton;
