import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import Header from './components/Header/Header';
import { MainContent } from './shared/layout';
import VesselStorage from './components/Storage/VesselStorage';
import Storage from './components/Storage/Storage';
import StorageDetails from './components/StorageDetails/StorageDetails';
import Extractions from './components/Extractions/Extractions';
import ExtractionDetails from './components/ExtractionDetails/ExtractionDetails';
import VesselProvider from './components/VesselContext/VesselContext';

function App() {
  return (
    <BrowserRouter>
      <VesselProvider>
        <Header />
        <MainContent>
          <Routes>
            <Route path="/" element={<Navigate to="/storage" />} />
            <Route path="/storage" element={<Storage />} />
            <Route path="/storage/:vesselId" element={<VesselStorage />} />
            <Route path="/storage/:storageId/details/" element={<StorageDetails />} />
            <Route path="/storage/:storageId/details/:loadingId" element={<StorageDetails />} />
            <Route path="/extractions" element={<Extractions />} />
            <Route path="/extractions/:id" element={<ExtractionDetails />} />
          </Routes>
        </MainContent>
      </VesselProvider>
    </BrowserRouter>
  );
}

export default App;
