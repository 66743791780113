import styled from 'styled-components';
import { breakpoints, spacing } from 'toc-styled-components';

export const HeaderLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const HeaderLinkContainerInner = styled.div`
  display: flex;
  @media (min-width: ${breakpoints.sm}) {
    align-items: center;
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
  > a {
    display: flex;
    align-items: center;
  }
  > * > span {
    margin-right: ${spacing(1)};
  }
`;
