import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { Input, Select } from 'toc-styled-components';
import VesselSelect from '../../../../Form/VesselSelect';
import HarvestingSince from './HarvestingSince';

function ExtractionFields() {
  const { register, watch } = useFormContext();

  const extractionMethod = watch('method');
  const isRZExtraction = extractionMethod === 'rz extraction';

  return (
    <>
      <VesselSelect />
      <Input
        {...register('name', { required: true })}
        required
        label={t('extactionName')}
        placeholder="S002-E-xxx"
        tooltip={t('toolTipExtractionName')}
      />
      <Select
        tooltip={t('tooltipExtractionMethod')}
        required
        label={t('method')}
        {...register('method', { required: true })}
      >
        <option value="rz extraction">{t('retentionZone')}</option>
        <option value="workboat">{t('workBoat')}</option>
      </Select>
      {isRZExtraction && <HarvestingSince />}
      <Input
        {...register('started_at', { required: true })}
        required
        label={t('startedAt')}
        type="datetime-local"
        tooltip={t('toolTipExtractionStartedAt')}
      />
    </>
  );
}

export default ExtractionFields;
