import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Table } from 'toc-styled-components';
import api from '../../lib/api';
import { Extraction } from '../../lib/types';
import Form from '../Form/Form';
import AddButton from '../Storage/components/AddButton/AddButton';
import AddExtractionModal from './components/AddExtractionModal/AddExtractionModal';

function Extractions() {
  const { t } = useTranslation();
  const [extractions, setExtractions] = useState<Extraction[]>([]);
  const [loading, setLoading] = useState(true);
  const [addExtractionModalOpen, setAddExtractionModalOpen] = useState(false);
  const navigate = useNavigate();

  const getExtractions = () => {
    setLoading(true);
    api.get(['extractions']).then((response) => {
      setExtractions(response.data);
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    getExtractions();
  }, []);

  return (
    <Card>
      <h2>{t('extractions')}</h2>
      <AddButton onClick={() => setAddExtractionModalOpen(true)}>{t('addExtraction')}</AddButton>
      <Form>
        <AddExtractionModal
          open={addExtractionModalOpen}
          onCancel={() => setAddExtractionModalOpen(false)}
          onConfirm={() => { setAddExtractionModalOpen(false); getExtractions(); }}
        />
      </Form>
      <Table active overflowy="auto" fullWidth loading={loading}>
        <tbody>
          <tr>
            <th>{t('name')}</th>
            <th>{t('extractionMethod')}</th>
            <th>{t('harvestingSince')}</th>
            <th>{t('startedAt')}</th>
          </tr>
          {extractions.map((e) => (
            <tr key={e.id} onClick={() => navigate(`/extractions/${e.id}`)}>
              <td>{e.name}</td>
              <td>{e.method}</td>
              <td>{e.harvesting_since}</td>
              <td>{e.started_at}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}

export default Extractions;
