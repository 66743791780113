import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Input } from 'toc-styled-components';
import ImageUpload from '../../../../../../ImageUpload/ImageUpload';
import VesselSelect from '../../../../../../Form/VesselSelect';
import PortSelect from '../../PortSelect';

function LoadStorageFields() {
  const { register, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <VesselSelect />
      <Input
        {...register('loaded_at', { required: true })}
        required
        label={t('loadedAt')}
        type="datetime-local"
        tooltip={t('toolTipLoadedAt')}
        error={!!errors.loaded_at}
      />
      <ErrorMessage message={errors.loaded_at && String(errors.loaded_at?.message)} />
      <ImageUpload
        {...register('storage_pictures', { minLength: 1 })}
        label={t('storagePictures')}
      />
      <ImageUpload
        {...register('tare_weight_pictures', { minLength: 1 })}
        label={t('tareWeightPictures')}
      />
      <PortSelect />
    </>
  );
}

export default LoadStorageFields;
