import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useState } from 'react';
import { Loader } from 'toc-styled-components';
import axios, { AxiosRequestConfig } from 'axios';
import { LoadingContainer } from '../../shared/layout';

type AuthWrapperProps = {
  children: ReactNode;
};

function AuthWrapper({ children }: AuthWrapperProps) {
  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading && isAuthenticated) {
      axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
        const newConfig = { ...config };
        if (!newConfig.headers) newConfig.headers = {};
        const token = await getAccessTokenSilently();
        newConfig.headers.Authorization = `Bearer ${token}`;
        return newConfig;
      });
      setReady(true);
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  if (ready) {
    return <div>{children}</div>;
  }
  return (
    <LoadingContainer>
      <Loader variant="primary" />
    </LoadingContainer>
  );
}

export default AuthWrapper;
