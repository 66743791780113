import { HeaderLink } from 'toc-styled-components';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Vessel } from '../../../lib/types';
import { vesselContext } from '../../VesselContext/VesselContext';

function VesselHeaderLinks() {
  const { t } = useTranslation();
  const vessels = useContext<Vessel[]>(vesselContext);

  return (
    <>
      {vessels.map((vessel) => (
        <HeaderLink key={vessel.id} as={NavLink} to={`/storage/${vessel.id}`}>
          {`${t('storage')} ${vessel.name.replace('MAERSK ', '')}`}
        </HeaderLink>
      ))}
    </>
  );
}

export default VesselHeaderLinks;
