import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal, Input, useAlert, Button,
} from 'toc-styled-components';
import { Props as ModalProps } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../lib/api';

type Props = {
  setSelectOption?: (selected_id: number) => void;
} & ModalProps;

function AddBagModal({
  onConfirm, setSelectOption, ...props
}: Props) {
  const {
    register, getValues, formState: { errors, isValid },
  } = useFormContext();
  const { alert } = useAlert();
  const { t } = useTranslation();

  const addBag = () => {
    api.post(['storage', 'other'], getValues()).then((response) => {
      alert({ message: t('completedSuccessfully') });
      if (setSelectOption) {
        setSelectOption(response.data.id);
      }
      onConfirm?.();
    });
  };

  return (
    <Modal
      footer={(
        <Button disabled={!isValid} onClick={addBag}>
          {t('add')}
        </Button>
      )}
      {...props}
    >
      <Input
        {...register('identifier', { required: true })}
        required
        label={t('identifier')}
        tooltip={t('toolTipBagIdentifier')}
      />
      <Input
        {...register('tare_weight', {
          required: { value: true, message: t('fieldRequired') },
          validate: { positiveNumber: (v) => (Number(v) >= 0 ? true : t('positiveField')) },
        })}
        required
        label={`${t('tareWeight')} (kg)`}
        type="number"
        min="0"
        step="1"
        error={!!errors.tare_weight}
        tooltip={t('toolTipBagTareWeight')}
      />
    </Modal>
  );
}

export default AddBagModal;
